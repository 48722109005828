import Vue from "vue";

export default {
    state: {
        currentOrder: false,
        selectedOrderLineIndex: false,
        refundOrder: false,
        unsentOrderLines: [],
        percentageDiscount: false,
        terminalText: null,
        QRUrl: null,
        autoNavigateUrl: null,
        nemtakeawayView: 'new_order',
        table_uuid: null,
        table_uuids: [],
        clients_number: null,
        awaitingCancelAction: false,
    },

    getters: {
        savedOrderLines: (state) => {
            return state.currentOrder !== false && 'order_lines' in state.currentOrder
                ? state.currentOrder.order_lines
                : [];
        },

        orderHasSingleItem: (state, getters) => {
            return getters.orderLines && getters.orderLines.length === 1 && getters.orderLines[0].quantity === 1;
        },

        orderSplitDisabledInPOS: (state, getters) => {
            return getters.everythingDisabled || getters.orderHasExternalOrder || !getters.orderIsAllPrinted ||getters.orderEmpty || !getters.paymentTypes.length || getters.orderHasSingleItem;
        },

        selectedOrderLineIndex: (state) => {
            return state.selectedOrderLineIndex;
        },

        selectedOrderLine: (state) => {
            if (state.selectedOrderLineIndex === false) {
                return false;
            } else {
                return state.unsentOrderLines[state.selectedOrderLineIndex];
            }
        },

        orderUuid: (state) => {
            return state.currentOrder.uuid;
        },

        orderEmpty: (state, getters) => {
            let savedOrderLines = getters.savedOrderLines;

            return state.currentOrder === false || ((savedOrderLines.length + state.unsentOrderLines.length) === 0);
        },

        orderHasStatus: (state) => (status) => {
            return state.currentOrder && state.currentOrder.status === status;
        },

        orderReadyToCancel: (state) => {
            return state.currentOrder && state.currentOrder.status === 'open' && state.currentOrder.unpaid === state.currentOrder.total;
        },

        // Near-duplicate method in SplitOrder; make sure to update there if making changes here. 
        unsentOrderLinesTotal: (state) => {
            let total = 0;

            let orderLineTotal = (sum, orderLine) => {
                let price = orderLine.price ?? orderLine.product.price;
                let lineTotal = orderLine.quantity * price;

                if (orderLine.percentage_discount != null && orderLine.percentage_discount > 0) {
                    // Important that we round the discount amount before subtracting from the original total; if the
                    // reasoning escapes you, ask wise man Wel
                    lineTotal = lineTotal - Math.round(lineTotal * (orderLine.percentage_discount / 1000));
                }

                if (orderLine?.slaves?.length) {

                    lineTotal += orderLine.slaves.reduce(orderLineTotal, 0);
                }

                return sum + lineTotal;
            }

            total = state.unsentOrderLines.reduce(orderLineTotal, 0);

            return total;
        },

        orderTotal: (state, getters) => {
            let total = (!getters.workingOnOpenOrder && state.currentOrder && !getters.secondContextIs('alsoMarkAsDelivered')) ? state.currentOrder.total : 0;
            return total + getters.unsentOrderLinesTotal;
        },

        orderBalance: (state, getters) => {
            return getters.unsentOrderLinesTotal - (getters?.order?.payments_total ?? 0);
        },

        orderLines: (state, getters) => {
            if (getters.workingOnOpenOrder) {
                return state.unsentOrderLines;
            } else if (getters.secondContextIs('alsoMarkAsDelivered')) { // The order is paid but should still appear visible
                return state.unsentOrderLines;
            } else {
                return getters.savedOrderLines.concat(state.unsentOrderLines);
            }
        },

        preparedOrderLines: (state, getters) => {
            let orderLines = state.unsentOrderLines;
            let preparedOrderLines = [];

            const prepareOrderLine = (orderLine) => {
                let preparedOrderLine;

                if ('uuid' in orderLine) {
                    // Refund or re-save order line case

                    preparedOrderLine = {
                        uuid: orderLine.uuid,
                        quantity: parseInt(orderLine.quantity),
                        optouts: orderLine.optouts ?? null,
                        //percentage_discount: orderLine.percentage_discount ?? null,
                    };
                } else {
                    preparedOrderLine = {
                        product_uuid: orderLine.product.uuid,
                        quantity: parseInt(orderLine.quantity),
                        price: orderLine.price ?? orderLine.product.price,
                        product_modifier_group: orderLine.product_modifier_group ?? null,
                        optouts: orderLine.optouts ?? null,
                    };
                }

                preparedOrderLine.comment = orderLine.comment ?? null;
                preparedOrderLine.receipt_text = orderLine.receipt_text ?? null;

                if (('percentage_discount' in orderLine)) {
                    // Percentage-discounted order line case

                    preparedOrderLine.percentage_discount = orderLine.percentage_discount;
                }

                if (orderLine?.slaves?.length) {
                    preparedOrderLine.slaves = [];
                    preparedOrderLine.slaves = orderLine.slaves.map(slave => prepareOrderLine(slave));
                }

                return preparedOrderLine;
            }

            for (const orderLine of orderLines) {
                preparedOrderLines.push(prepareOrderLine(orderLine));
            }

            return preparedOrderLines;
        },

        indexOfUnsentOrderLineWithSameUuidAndPrice: (state) => (orderLine) => {
            /* const uuidPriceEquality = (o1, o2) => (
                o1.product.uuid === o2.product.uuid &&
                o1.product.price === o2.product.price
            ); */
            function uuidPriceEq(o1, o2) {
                const getProductPrice = (o) => (('price' in o) ? o.price : o.product.price);

                return o1.product.uuid === o2.product.uuid && getProductPrice(o1) === getProductPrice(o2);
            }

            function productNameEq(lineOne, lineTwo) {
                // Okay, so we just decided (maybe we had already, and I forgot) that pre-save order lines (order lines
                // that became part of an open order when it was saved) should not be combined (in the frontend) with any
                // new additions. By not looking at each orderlines product.name (only product_name), we accomplish that. ^^
                
                return lineOne.product_name === lineTwo.product_name;
            }

            function orderLineEq(lineOne, lineTwo) {
                // Check price equality at current level
                const priceEqualityOfCurrentPair = uuidPriceEq(lineOne, lineTwo);
                const productNameEqualityOfCurrentPair = productNameEq(lineOne, lineTwo);
                //const neitherHasSlave = (lineOne.slave === undefined && lineTwo.slave === undefined);

                // Begin checking their slave(s)
                const lineOneSlaves = lineOne?.slaves?.length ?? 0;
                const lineTwoSlaves = lineTwo?.slaves?.length ?? 0;

                // Check if they have the same number of slaves
                let slavesEqual = lineOneSlaves === lineTwoSlaves;
                
                if (slavesEqual) {
                    // They have the same number of slaves; we begin recursively checking them pair by pair
                    for (let i = 0; i < Math.max(lineOne, lineTwo); i++) {
                        // If any one inequality is encountered, slavesEqual will be false
                        slavesEqual = slavesEqual && orderLineEq(lineOne.slaves[i], lineTwo.slaves[i])

                        if (!slavesEqual) {
                            // If slavesEqual doesn't evaluate to true, we break from the loop, slavesEqual remaining false
                            break;
                        }
                    }
                }
                
                return priceEqualityOfCurrentPair && productNameEqualityOfCurrentPair && slavesEqual;
            }

            let index = state.unsentOrderLines.findIndex(o => orderLineEq(o, orderLine));

            return index;
        },

        order: (state) => {
            return state.currentOrder;
        },

        workingOnOpenOrder: (state, getters) => {
            return getters.orderHasStatus('open') && getters.firstContextIs('sale') && getters.secondContextIsnt('considerLeftOrder');
        },

        percentageDiscount: (state) => {
            return state.currentOrder && state.percentageDiscount;
        },

        isRefundOrder: (state) => {
            return state.currentOrder && state.currentOrder.original_order;
        },

        discountsActive: (state) => {
            return [...new Set(state.unsentOrderLines.map(o => o.percentage_discount != null ? o.percentage_discount : null ))];
        },

        orderHasTable: (state) => {
            return state.currentOrder && state.currentOrder.tables.length;
        },

        orderHasNoTable: (state) => {
            return state.currentOrder === false || state.currentOrder.tables.length === 0;
        },

        // Should return the single active table, if it exists, and otherwise an array of all tables the order has been assigned to
        // IMPTODO: Move logic to backend!!
        tables: (state, getters) => {
            if (state.currentOrder === false ) {
                return [];
            }

            if (state.currentOrder.tables.length === 0) {
                return [];
            }

            if (state.currentOrder.tables.length > 0) {
                let tables = [];
                if (getters.orderHasStatus('open')) {
                    let table = state.currentOrder.tables.find(table => table.pivot.active === 1);

                    if (table !== -1) {
                        tables.push(table);
                    }
                } else if (getters.orderHasStatus('completed') || getters.orderHasStatus('cancelled')) {
                    tables.push(state.currentOrder.tables[0]);
                }

                return tables;
            }
        },

        orderHasExternalOrder: (state) => {
            return state.currentOrder && state.currentOrder.external_order !== null;
        },

        orderIsAllPrinted: (state) => {
            return state.currentOrder && state.currentOrder.all_printed;
        },

        terminalText: (state) => {
            return state.terminalText;
        },
        QRUrl: (state) => {
            return state.QRUrl;
        },
        autoNavigateUrl: (state) => {
            return state.autoNavigateUrl;
        },        
        nemtakeawayView: (state) => {
            return state.nemtakeawayView;
        },

        orderHasPayments: (state) => {
            return state.currentOrder.payments.length > 0;
        },

        orderHasCapturedPayments: (state) => {
            if (!state.currentOrder.payments || state.currentOrder.payments.length === 0) {
                return false;
            }

            for (const payment of state.currentOrder.payments) {
                if (payment.cancelled_at == null) {
                    return true; // At least one payment is not cancelled, so order shouldn't be reopened
                }
            }

            return false;
        },

        orderAssignedName: (state) => {
            return state.currentOrder.assigned_name;
        },
        
        getTableUuid: (state) => {
            return state.table_uuid;
        },
        
        getTableUuids: (state) => {
            return state.table_uuids;
        },

        getClientsNumber: (state) => {
            return state.clients_number;
        },

        returnMoneyShouldBeGiven: (state, getters) => {
            const order = state.currentOrder;

            if (!order) {
                return false;
            }

            if (!getters.contextIs(['sale', false])) {
                return false;
            }

            if (state.awaitingCancelAction) {
                return true;
            } else {
                // Return true if the order balance's sign is different from the total's
                return getters.orderBalance * getters.orderTotal < 0;
            }

        },

        orderAwaitingCancelAction: (state, getters) => {
            return state.awaitingCancelAction;
        },
    },

    mutations: {
        setSelectedOrderLineIndex (state, index) {
            state.selectedOrderLineIndex = index;
        },

        addPendingOrderLine (state, orderLine) {
            state.unsentOrderLines.push(orderLine);

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        changeOrderLineQtyAtIndex (state, data) {
            if (data.rootIndex != undefined) {
                state.unsentOrderLines[data.rootIndex].quantity += data.change;
    
                if (state.unsentOrderLines[data.rootIndex]?.slaves) {
                    state.unsentOrderLines[data.rootIndex].slaves.map(slaveLine => slaveLine.quantity += data.change);
                }
            } else if (data.indexes != undefined) {
                if (data.indexes.length === 1) {
                    state.unsentOrderLines[data.indexes[0]].quantity += data.change;
                } else if (data.indexes.length === 2) {
                    state.unsentOrderLines[data.indexes[0]].slaves[data.indexes[1]].quantity += data.change;
                }
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        setOrderLineQtyAtIndex (state, data) {
            if (data.rootIndex != undefined) {
                state.unsentOrderLines[data.rootIndex].quantity = data.newQty;
    
                if (state.unsentOrderLines[data.rootIndex]?.slaves) {
                    state.unsentOrderLines[data.rootIndex].slaves.map(slaveLine => slaveLine.quantity = data.newQty);
                }
            } else if (data.indexes != undefined) {
                // IMPTODO: Don't know what this code is supposed to be, or that this case should exist; agree with Wel first
                if (data.indexes.length === 1) {
                    state.unsentOrderLines[data.indexes[0]].quantity = data.newQty;
                } else if (data.indexes.length === 2) {
                    state.unsentOrderLines[data.indexes[0]].slaves[data.indexes[1]].quantity = data.newQty;
                }
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        replaceOrderLineAtIndex(state, data) {
            Vue.set(state.unsentOrderLines, data.index, data.orderLine);

            // Ugh... to ensure correct order total and balance after updating an order line with modifiers
            state.unsentOrderLines.push(1);
            state.unsentOrderLines.pop();
        },

        removeOrderLineAtIndex(state, index) {
            state.unsentOrderLines.splice(index, 1);

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        clearUnsentOrderLines(state) {
            state.unsentOrderLines = [];
            state.selectedOrderLineIndex = false;
        },

        setOrderLines(state, orderLines) {
            state.unsentOrderLines = orderLines;
        },

        setCurrentOrder(state, order) {
            state.currentOrder = order;
        },

        setCurrentOrderStatus(state, status) {
            let order = state.currentOrder;
            order['status'] = status;
            state.currentOrder = order;
        },

        setCurrentAsRefundOrder(state) {
            state.refundOrder = state.currentOrder;
        },

        setRefundOrder(state, value) {
            state.refundOrder = value;
        },

        copySavedOrderLines(state) {
            state.unsentOrderLines = state.currentOrder.order_lines;
        },

        setOrderPercentageDiscount(state, value) {
            if (state.currentOrder !== false) {
                state.percentageDiscount = value;

                let valueToSend = value === false ? null : value;

                const setDiscount = (o) => {
                    Vue.set(o, 'percentage_discount', valueToSend);

                    o.slaves.forEach((s) => {
                        Vue.set(s, 'percentage_discount', valueToSend);
                    });
                }

                state.unsentOrderLines.forEach(setDiscount);
                state.currentOrder.all_printed = false;
            }
        },

        setOrderLinePercentageDiscount(state, value) {
            Vue.set(state.unsentOrderLines[state.selectedOrderLineIndex], 'percentage_discount', value);

            state.unsentOrderLines[state.selectedOrderLineIndex].slaves.forEach((s) => {
                if (s.product_modifier_group) {
                    Vue.set(s, 'percentage_discount', value);
                }
            });

            if (state.unsentOrderLines.length === 1) {
                state.percentageDiscount = value ?? false;
            }

            if (state.currentOrder) {
                state.currentOrder.all_printed = false;
            }
        },

        // The above function will actually apply or remove a percentage discount from an order's order lines. The below
        // function will be called when order lines are added to a discount order; since new order lines should not have
        // the previous order lines' discount, we should mark it in the state that the percentage discount is not active,
        // in order that the discount (or another discount) can be (re-)applied to all order lines.
        setPercentageDiscountIndicator(state, value) {
            state.percentageDiscount = value;
        },

        setOrderLineComment(state, comment) {
            Vue.set(state.unsentOrderLines[state.selectedOrderLineIndex], 'comment', comment);
        },

        setOrderLineReceiptText(state, receiptText) {
            Vue.set(state.unsentOrderLines[state.selectedOrderLineIndex], 'receipt_text', receiptText);
        },

        setTableUuid(state, uuid) {
            state.table_uuid = uuid;
        },

        setTableUuids(state, uuids) {
            state.table_uuids = uuids;
        },

        setClientsNumber(state, number) {
            state.clients_number = number;
        },

        setTerminalText(state, text) {
            state.terminalText = text;
        },
        setQRUrl(state, url) {
            state.QRUrl = url;
        },
        setAutoNavigateUrl(state, url) {
            state.autoNavigateUrl = url;
        },          
        setNemtakeawayView(state, newView) {
            state.nemtakeawayView = newView;
        },

        setOrderAssignedName(state, name) {
            if (state.currentOrder) {
                state.currentOrder.assigned_name = name;
            }
        },

        // Uhm, move this when finished
        precautionarySaveOrderLinesLengthToLocalStorage(state)
        {
            window.localStorage.setItem('orderLines', state.unsentOrderLines.length);
        },

        setAwaitingCancelAction(state, value) {
            state.awaitingCancelAction = value;
        },
    },

    actions: {
        updateOrder({ state, commit, getters }, payload) {
            console.log('updateOrder')
            console.log('state.currentOrder.uuid ' + state.currentOrder.uuid)
            commit('setAwaitingUpdateOrderResponse', true);

            let params = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                order_lines: getters.preparedOrderLines,
                test_mode: getters.testMode,
                suspend: true,
            };

            // We use destructuring assignment in order to avoid having to provide extraParams every time
            const { extraParams = {} } = payload ?? {};

            console.log('extraParams in updateOrder')
            console.log(extraParams)

            // Probably pass this along with extraParams?
            if (getters.order.assigned_name) {
                params.assigned_name = getters.order.assigned_name;
            }

            if (extraParams) {
                params = { ...params, ...extraParams };
            }

            // TODO: Handle error i.e. by creating a notif? Or let calling methods do so?
            return axios.put('orders/' + state.currentOrder.uuid, params)
                .then(response => {
                    const order = response.data.order;

                    commit('setCurrentOrder', order);

                    return response;
                }).finally(() => {
                    commit('setAwaitingUpdateOrderResponse', false);
                });
        },

        replacementProductionPrint({ commit, getters }, { uuid }) {
            const data = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                test_mode: getters.testMode,
            }

            return axios.post('orders/' + uuid + '/replacement-production-print', data);
        },

        retainOrder({ commit, getters }, { uuid }) {
            const params = {
                register_uuid: getters.register.uuid,
                cashier_uuid: getters.cashier.uuid,
                test_mode: getters.testMode,
            }

            return axios.put('orders/' + uuid + '/retain', params);
        },
    }
}