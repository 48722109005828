import Vue from 'vue';
import Vuex from 'vuex';
import booking from './booking';
import resources from './resources';
import context from './context';
import order from './order';
import cashReport from './cash_report';
import externalOrder from './external_order';
import notifications from './notifications';
import nta from './nta'
import product_builder_store from './product_builder_store';
import takeOverQuery from './take_over_query';
import language from './language';
import setAxiosCompany from '@/assets/js/SharedFunctions.js';

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        packageVersion: process.env.PACKAGE_VERSION || '0',
        maintenanceMode: false,
        cashierSignedInOnce: false,
        windowClicked: false,

        user: false,
        impersonatingUser: false,
        cashier: false,
        pendingInvitations: 0,

        selectedRegister: false,
        selectedRegisterPageIndex: 0, // Put this because easier than to avoid grid component recreation
        selectedCompany: false,
        alternativeCompanies: [],

        paymentTypes: [],

        lastCompletedNormalOrderUuid: false,

        virtualPrintJob: false,
        lastBoxStatusCheck: false,

        areas: [],
        tables: [], // TODO: Retire this an associated setter!

        adminPINAuthorised: false,
        mobilepaypos_mode: 'live',
    }
}

// initial state
const state = getDefaultState()

const actions = {
    resetCartState ({ commit }) {
        commit('resetState')
    },

    getUserData ({ commit, state, actions }, { companyUuid, tentativeCompany }) {
        if (!companyUuid) {
            if (companyUuid !== null) {
                // We accept only null; another falsy value indicates programmer confusion

                throw new Error("getUserData called without a company UUID or null. Value used: " + companyUuid);
            }
        }

        let setup = {};

        if (companyUuid) {
            setAxiosCompany(companyUuid);
            
            setup.headers = {
                'Company-Uuid': companyUuid
            }

            if (tentativeCompany) {
                setup.params = {
                    tentative_company: tentativeCompany
                }
            }
        }

        return axios.get('users/get-signed-in-user-data', setup).then(response => {
            let responseData = response.data;

            if ('company' in responseData) {
                const company = responseData.company;

                commit('setCompany', company);

                setAxiosCompany(company.uuid);
            } else {
                commit('setCompany', false);

                setAxiosCompany(null);
            }

            commit('setUser', responseData.user);

            commit('setPendingInvitations', responseData.pending_invitations);
            commit('setAlternativeCompanies', responseData.other_companies);

            return response;
        }).catch(error => {
            throw error;
        });
    },

    refreshUserData ({ commit, state, dispatch }) {
        const company = state.selectedCompany;
        const companyUuid = company ? company.uuid : null;

        dispatch('getUserData', { companyUuid: companyUuid });
    },

    verifyAdminPIN ({ commit, state }, { pinType, pin }) {
        commit('setIsProcessingRequest', 'verifyAdminPIN')
        return axios.post(`registers/${state.selectedRegister.uuid}/authorise-pos-admin`, { pin_type: pinType, pin });
    },
}

const mutations = {
    resetState (state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },

    setMobilepayposMode: (state, mode_val) => {
        state.mobilepaypos_mode = mode_val;
    },
    
    setWindowClicked (state) {
        state.windowClicked = true;
    },
    
    signOut (state) {
        // TODO: Should this be an action instead? Any practical difference?

        state.user = false;
        state.selectedCompany = false;
        state.selectedRegister = false;
    },

    setUser (state, user) {
        state.user = user;

        if (user.pages_available && 'object' === typeof user.pages_available) { // convert object to array to use later 'includes()' function
            const pagesObj = user.pages_available;
            const pages = Object.keys(pagesObj).map((key) => pagesObj[key]);
            state.user.pages_available = pages;
        }
    },

    setCashier (state, cashier) {
        state.cashierSignedInOnce = true;
        state.cashier = cashier;
    },

    setPendingInvitations (state, number) {
        state.pendingInvitations = number;
    },

    setCompany (state, company) {
        state.selectedCompany = company;
    },

    setAlternativeCompanies (state, alternativeCompanies) {
        state.alternativeCompanies = alternativeCompanies;
    },

    setAlternativeCompanyVisibilityInSearch (state, uuidAndValue) {
        const uuid = uuidAndValue[0];
        const value = uuidAndValue[1];
        state.alternativeCompanies.find(c => c.uuid == uuid).is_visible_in_search = value;
    },

    setRegister (state, register) {
        if (register.current_sales_area && register.current_sales_area.pages) {
            register.current_sales_area.pages.forEach(page => {
                page.elements.forEach(element => {
                    element.product = register.products.find(product => product.uuid === element.product_uuid);
                });
            });
        }

        state.selectedRegister = register; // IMPTODO: Remove payment_types
        state.paymentTypes = register.payment_types;
    },

    setRegisterDisabled(state, text) {
        state.selectedRegister.disabled = true;
        state.selectedRegister.disabled_reason = text;
    },

    setSelectedRegisterPageIndex(state, index) {
        state.selectedRegisterPageIndex = index;
    },

    setLastCompletedNormalOrderUuid(state, uuid) {
        state.lastCompletedNormalOrderUuid = uuid;
    },

    updateSetupStatusToReflectNewlyCreatedPaymentType(state) {
        if (state.selectedCompany) {
            state.selectedCompany.setup_status.payment_types = true;
        }
    },

    setVirtualPrintJob(state, jobData) {
        state.virtualPrintJob = jobData;
    },

    setBoxStatus(state, response) {
        state.lastBoxStatusCheck = {
            time: new Date(),
            websocket: response.websocket_available,
            box: response.box_alive,
        };
    },

    setAreas(state, areas) {
        Vue.set(state, 'areas', areas);
    },

    resetAreas(state) {
        Vue.set(state, 'areas', []);
    },

    setAreaAtIndex(state, data) {
        Vue.set(state.areas, data.index, data.area);
    },

    setTables(state, tables) {
        state.tables = tables;
    },

    updateTableOrders(state, data) {
        let matchingTable = state.tables.find((t) => t.uuid === data.table_uuid);

        if (matchingTable !== undefined) {
            let matchingOrderIndex = matchingTable.orders.findIndex((o) => o.uuid === data.order.uuid);

            if (matchingOrderIndex !== -1) {
                matchingTable.orders[matchingOrderIndex] = data.order;
            }
        }
    },

    // TODO: Instead fetch refreshed data from backend
    setInitialisationSuccess(state) {
        state.selectedCompany.setup_status.system_resources = true;
    },

    setSalesAccountsAndVatQuickSetupSuccess(state) {
        state.selectedCompany.setup_status.accounts_or_vat_types = true;
    },

    setPaymentTypesQuickSetupSuccess(state) {
        state.selectedCompany.setup_status.payment_types = true;
    },
    
    setCashiersSetupStatus(state, value) {
        if (state.selectedCompany) {
            state.selectedCompany.setup_status.cashiers = value;
        }
    },

    setAdminPINAuthorised(state, value) {
        state.adminPINAuthorised = value;
    },

    setMaintenanceMode(state, value) {
        state.maintenanceMode = value;
    },

    setVippsmobilepayposMSN(state, value) {
        state.selectedCompany.vippsmobilepaypos_msn = value;
    },

    setImpersonatingUser(state, userOrFalse) {
        state.impersonatingUser = userOrFalse;

        if (userOrFalse) {
            let user = userOrFalse;

            window.sessionStorage.setItem('impersonatingUuid', user.uuid);
            window.sessionStorage.setItem('impersonatingName', user.name);
        } else {
            window.sessionStorage.removeItem('impersonatingUuid');
            window.sessionStorage.removeItem('impersonatingName');
        }
    },
}

export default new Vuex.Store({
    state,
    getters: {
        showUnpaidPopup: (state) => {
            return state.selectedCompany.payment_status === 'block' || state.selectedRegister.company_payment_status === 'block';
        },

        showRegisterDisabledPopup: (state) => {
            return state.selectedRegister.disabled;
        },

        getRegisterDisabledReason: (state) => {
            return state.selectedRegister.disabled_reason;
        },

        appVersion: (state) => {
            return state.packageVersion
        },

        company: (state) => {
            return state.selectedCompany;
        },

        // Needed a getter for the first (?) Vuex actions, then realised it is pretty much not ambiguous what this getter
        // does, i.e. I think we can stop using state.selectedRegister (but will not execute a full search and replace now)
        register: (state) => {
            return state.selectedRegister;
        },

        cashier: (state) => {
            return state.cashier;
        },
        
        hasSelectedCompany: (state) => {
            return state.selectedCompany !== false;
        },

        hasUserData: (state) => {
            return state.user !== false;
        },

        paymentTypes: (state) => {
            return state.paymentTypes.filter(p => p.interface.becomes_pos_payment_button);
        },

        hasPaymentTypeWithClass: (state) => (nameOfClass) => {
            return state.paymentTypes && state.paymentTypes.some(paymentType => paymentType.interface.key === nameOfClass);
        },

        companyHasTables: (state, getters) => {
            return getters.hasSelectedCompany && state.selectedCompany.setup_status.tables;
        },

        alternativeCompanies: (state) => (filter) => {
            const alternativeCompanies = state.alternativeCompanies;
            
            if (!filter || !filter.length) {
                // A filter wasn't provided

                return alternativeCompanies;
            }

            // A filter was provided
            const lowerCaseFilter = filter.toLowerCase();
            return alternativeCompanies.filter(
                company =>
                (
                    company.name && company.name.toLowerCase().includes(lowerCaseFilter) ||
                    ('' + company.id).includes(lowerCaseFilter) ||
                    (company.display_name && company.display_name.toLowerCase().includes(lowerCaseFilter))
                )
            );
        },

        totalAvailableCompanies: (state) => {
            return state.alternativeCompanies.length + (state.selectedCompany ? 1 : 0);
        },

        companyIsEnterprise: (state) => {
            return state.selectedCompany && state.selectedCompany.type.name === 'enterprise';
        },

        // Looking forward to a more elegant way to authorise...
        companyIsMediumOrNormalSubcompanyOrEnterprise: (state) => {
            return state.selectedCompany.subscription_type?.name === 'medium' || ['enterprise', 'subcompany'].includes(state.selectedCompany.type.name);
        },

        companyIsRestrictedSubcompany: (state) => {
            return state.selectedCompany.type.name === 'restricted_subcompany';
        },

        companyIsSubcompany: (state) => {
            return ['restricted_subcompany', 'subcompany'].includes(state.selectedCompany.type.name);
        },

        companyIsIndependent: (state) => {
            return state.selectedCompany.type.name === 'independent';
        },

        companyAddonsLimitAllows: (state) => (type) => {
            // temporary, add addon for real usage
            if ('beb_integration' === type) {
                return true;
            }

            return state.selectedCompany.addons_allowed[type];
        },
        
        companyIsntIndependent: (state) => {
            return state.selectedCompany.type.name !== 'independent';
        },

        companyHasType: (state) => (typeName) => {
            return state.selectedCompany.type === typeName;
        },

        companyHasSubscriptionType: (state) => (type) => {
            return state.selectedCompany.subscription_type?.name === type;
        },

        companyHasOneOfSubscriptionTypes: (state) => (acceptedTypes) => {
            return acceptedTypes.includes(state.selectedCompany.subscription_type?.name);
        },

        companyRegistersLimit: (state) => {
            return state.selectedCompany.registers_limit;
        },

        companyHasScheduleExternalOrdersButtonEnabled: (state, getters) => {
            return getters.registerSettingIs('show_scheduled_external_order_button', true) && getters.getRegisterSetting('external_order_remote_link')?.length > 0;
        },

        nonEmptySalesOrder: (state, getters) => {
            return getters.firstContextIs('sale') && !getters.orderEmpty;
        },

        hasPrinter: (state) => {
            return (state.selectedRegister.printer !== null) ||
                ('printers' in state.selectedRegister && state.selectedRegister.printers.length > 0); // For old, multi-printer way; keeping it here to prevent future transition errors
        },

        terminalUsesNtaBox: (state) => {
            return state.selectedRegister.terminals.length > 0 && state.selectedRegister.terminals.some(terminal => terminal.box !== null);
        },

        hasTerminals: (state) => {
            return state.selectedRegister.terminals.length > 0;
        },

        hasScale: (state) => {
            return state.selectedRegister.scale !== null;
        },

        companyInitialisationComplete: (state) => {
            return state.selectedCompany && state.selectedCompany.setup_status.system_resources;
        },

        quickSetupAvailableForCompany: (state) => {
            return !['enterprise', 'restricted_subcompany'].includes(state.selectedCompany.type.name);
        },

        hasOrdinaryAccountsOrVatTypes: (state) => {
            return state.selectedCompany.setup_status.accounts_or_vat_types;
        },

        hasOrdinaryPaymentTypes: (state) => {
            return state.selectedCompany.setup_status.payment_types;
        },

        // Used to determine whether the quick setup option should be available
        finishedAllBasicSetup: (state, getters) => {
            const statuses = state.selectedCompany.setup_status;

            return getters.companyInitialisationComplete && statuses.payment_types && statuses.accounts_or_vat_types;
        },

        productWithPluOrBarcode: (state) => (pluOrBarcode) => {
            let barcodeMatch = null;

            for (const product of state.selectedRegister.products) {
                if (product.plu === parseInt(pluOrBarcode)) {
                    return product;
                }

                if (!barcodeMatch && product.barcode === pluOrBarcode) {
                    barcodeMatch = product;
                }
            }

            return barcodeMatch; // null if no match
        },

        productWithBarcode: (state) => (barcode) => {
            for (const product of state.selectedRegister.products) {
                if (product.barcode === barcode) {
                    return product;
                }
            }

            return null;
        },

        productWithUuid: (state) => (uuid) => {
            for (const product of state.selectedRegister.products) {
                if (product.uuid === uuid) {
                    return product;
                }
            }

            return null;
        },

        zReportOpen: (state) => {
            return state.selectedRegister.open_z_report !== null;
        },

        zReportClosed: (state) => {
            return state.selectedRegister.open_z_report === null;
        },

        // Iterates over the selectedRegister's elements and returns the product with the supplied uuid. If no such product
        // is found, it returns null. This function will be used to search an updated array of pages and elements for
        // product updates (availability changes, price updates) when the register is refreshed.
        getProductFromUuid: (state, getters) => (uuid) => {
            for (let page of state.selectedRegister.pages) {
                for (let element of page.elements) {
                    // Would find be more efficient here?
                    if (element.product !== null && element.product.uuid === uuid) {
                        return element.product;
                    }
                }
            }

            return null;
        },

        numberOfPendingInvitations: (state) => {
            return state.pendingInvitations;
        },

        isWsyPerson: (state) => {
            return (state.user && state.user.wsy_role !== null);
        },

        isWsyAdmin: (state) => {
            return (state.user && state.user.wsy_role === 'wsy_admin');
        },

        hasCompanyRole: (state) => (role) => {
            return (state.user && state.user.company_roles && state.user.company_roles.includes(role));
        },

        hasNoCompanyRole: (state) => {
            return (!state.user || state.user && !state.user.company_roles || state.user.company_roles.length == 0);
        },

        canAccessPage: (state) => (page) => {
            return state.user && state.user.pages_available && state.user.pages_available.includes(page);
        },

        canAccessConfigurationMenu: (state) => () => {
            const configurationPages = ['user', 'cashier', 'register', 'account', 'vat_type', 'payment_type', 'printer', 'terminal', 'box', 
                'customer', 'integration', 'settings'];

                // if available and required pages array intersection exists
            return state.user && state.user.pages_available && state.user.pages_available.some(item => configurationPages.includes(item));
        },

        userLoaded: (state) => {
            return state.user;
        },

        isWsyPersonOrCompanyAdmin: (state, getters) => {
            return getters.hasCompanyRole('admin') || getters.isWsyPerson;
        },

        /* cashPaymentTypes: (state) => {
            return state.paymentTypes.filter(t => t.interface.key === 'cash');
        }, */
        paymentTypesRequiringPosCounts: (state) => {
            return state.paymentTypes.filter((p) => !p.interface.provides_terminal_integration && !p.copy_total_to_close_count && p.interface.key !== 'invoice');
        },

        integratedPaymentTypes: (state) => {
            return state.paymentTypes.filter((p) => p.interface.provides_terminal_integration);
        },

        // Return true if the register has opened a Z-report in test mode
        testMode: (state) => {
            return state.selectedRegister.open_z_report != null && state.selectedRegister.open_z_report.test_mode;
        },

        getCompanySetting: (state) => (key) => {
            return state.selectedCompany.settings[key];
        },

        companySettingIs: (state) => (key, value) => {
            return state.selectedCompany && (key in state.selectedCompany.settings) && (state.selectedCompany.settings[key] === value);
        },

        getRegisterSetting: (state) => (key) => {
            return state.selectedRegister.settings[key];
        },

        registerSettingIs: (state) => (key, value) => {
            return state.selectedRegister && (key in state.selectedRegister.settings) && (state.selectedRegister.settings[key] === value);
        },

        companyHasIntegrationsSupportingExternalOrders: (state) => {
            return state.selectedCompany && state.selectedCompany.has_integrations_supporting_external_orders === true;
        },

        companyHasInvoiceAddonOrPastInvoices: (state, getters) => {
            return state.selectedCompany && (getters.companyAddonsLimitAllows('pos_invoicing') || state.selectedCompany.has_past_invoices === true);
        },

        floorPlanIsDefault: (state, getters) => {
            return getters.register && getters.register.settings.register_main_display === 'tables';
        },

        areas: (state) => {
            return state.areas;
        },

        currentSalesArea: (state) => {
            return state.selectedRegister?.current_sales_area;
        },

        currentSalesAreaProductButtonSize: (state) => {
            const size = state.selectedRegister.current_sales_area.product_button_size;

            if (size === 'big') {
                return '100px';
            }
            if (size === 'medium') {
                return '75px';
            }
            if (size === 'small') {
                return '50px';
            }

            console.log('unexpected size of heightButtonProduct(): ' + size)
        },

        currentSalesAreaCategoryButtonSize: (state) => {
            const size = state.selectedRegister.current_sales_area.category_button_size;

             if (size === 'big') {
                return '100px';
            }
            if (size === 'medium') {
                return '75px';
            }
            if (size === 'small') {
                return '50px';
            }

            console.log('unexpected size of heightButtonCategory(): ' + size)
        },

        alternativeSalesAreas: (state, getters) => {
            const currentSalesArea = getters.currentSalesArea;

            if (currentSalesArea) {
                return getters.register.sales_areas.filter(salesArea => salesArea.uuid !== currentSalesArea.uuid);
            }

            return getters.register.sales_areas;
        },

        mobilepayposMode: (state) => {
            return state.mobilepaypos_mode;
        },

        // The model must have set company.id!
        modelBelongsToSelectedCompany: (state, getters) => (model) => {
            return state.selectedCompany && state.selectedCompany.id === model.company.id;
        },

        // Currently, this method will aid in colouring blue every row corresponding to a "shared" (owned by an enterprise
        // company) resource, while resources owned conventionally by a company performing sales will have a normal colour,
        // and subcompany-owned resources will be yellow when seen from the mothership. I am not sure this makes sense, but
        // I spend some time thinking about it, and I have begun believing it does (listen to customers or ask me for the
        // reasoning, heh)
        tableAccentClass: (state, getters) => (model) => {
            if (state.selectedCompany) {
                if (getters.companyIsEnterprise) {
                    if (getters.modelBelongsToSelectedCompany(model)) {
                        return 'table-info';
                    } else {
                        return 'table-warning';
                    }
                }
            }

            return null;
        },

        notOwnerTranslationKey: (state, getters) => {
            return getters.companyIsEnterprise ? 'admin.owned_by_subcompany' : 'admin.owned_by_parent_company';
        },

        // Flags live on the company level, but currently, we only have one flag impacting the POS, so it is shipped
        // with the other regiter data, and I will use this getter to check
        registerFlagIsTrue: (state, getters) => (flagKey) => {
            return getters?.register.flags?.[flagKey];
        },

        registerHasInvoiceButton: (state, getters) => {
            return getters.registerSettingIs('allow_conversion_to_invoice', true) && getters.companyAddonsLimitAllows('pos_invoicing');
        },
    },

    mutations,
    actions,

    modules: {
        booking,
        context,
        resources,
        order,
        cashReport,
        externalOrder,
        notifications,
        nta,
        product_builder_store,
        takeOverQuery,
        language,
    }
});